
import StickyPage from "@/components/sticky-page.vue";
export default {
  components: {
    StickyPage,
  },
  setup() {
    const yoHomeToBelair = "fuzzydice";
    return { yoHomeToBelair };
  },
};
