<template>
  <sticky-page>
    <div class="card card-style mt-5">
      <div class="content mb-0">
        <h3>Pomodoro</h3>
        <p class="mb-4">
          Simple Pomodoro timer. Customize the breaks and alarms.
        </p>
      </div>
    </div>
    <div class="timeline-body mt-0">
      <div class="timeline-deco mt-5"></div>

      <div class="timeline-item">
        <span class="timeline-icon bg-red2-dark font-14">Work</span>
        <div class="timeline-item-content rounded-s">
          <h5>25:00</h5>
          <p class="mb-0">
            Work for 25 minutes without interruption
          </p>
          <!-- <a href="#" class="pt-3 d-block font-12">Read More</a> -->
        </div>
      </div>
      <div class="timeline-item">
        <span class="timeline-icon bg-green1-dark font-14">Short</span>
        <div class="timeline-item-content rounded-s">
          <h5>5:00</h5>
          <p class="mb-0">
            Take a short break
          </p>
          <!-- <a
            href="#"
            class="bg-highlight btn btn-sm rounded-sm font-12 mt-4 text-uppercase font-700"
            >Read More</a
          > -->
        </div>
      </div>
      <div class="timeline-item">
        <span class="timeline-icon bg-blue2-dark font-14">Long</span>
        <div class="timeline-item-content rounded-s">
          <h5>15:00</h5>
          <p class="mb-0">
            Enjoy a longer break and reset
          </p>
          <a
            href="#"
            class="bg-highlight btn btn-sm btn-full rounded-sm font-12 mt-4 text-uppercase font-700"
            >Read More</a
          >
        </div>
      </div>
      <div class="timeline-item">
        <span class="timeline-icon bg-yellow1-dark font-14">2017</span>
        <div class="timeline-item-content rounded-s pb-2">
          <!--- Info Group-->
          <div class="d-flex mb-n3">
            <div class="mr-auto">
              <i class="fa fa-circle font-32 pr-2 color-green1-dark"
                ><fa icon="circle"
              /></i>
            </div>
            <div class="mr-auto pr-2">
              <h1 class="font-24 mt-n1 text-center">90%</h1>
              <p class="mt-n3 font-10 text-center">Group Title</p>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-green1-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-green1-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-green1-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-green1-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-green1-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-green1-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-green1-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-green1-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-green1-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
          </div>
          <!--- Info Group-->
          <div class="d-flex mb-n3">
            <div class="mr-auto">
              <i class="fa fa-circle font-32 pr-2 color-blue2-dark"
                ><fa icon="circle"
              /></i>
            </div>
            <div class="mr-auto pr-2">
              <h1 class="font-24 mt-n1 text-center">50%</h1>
              <p class="mt-n3 font-10 text-center">Group Title</p>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-blue2-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-blue2-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-blue2-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-blue2-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-blue2-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
          </div>
          <!--- Info Group-->
          <div class="d-flex mb-n4">
            <div class="mr-auto">
              <i class="fa fa-circle font-32 pr-2 color-red2-dark"
                ><fa icon="circle"
              /></i>
            </div>
            <div class="mr-auto pr-2">
              <h1 class="font-24 mt-n1 text-center">30%</h1>
              <p class="mt-n3 font-10 text-center">Group Title</p>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-red2-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-red2-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male color-red2-dark px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
            <div class="ml-auto">
              <i class="fa fa-male opacity-30 px-1 font-27"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </sticky-page>
</template>

<script lang="ts">
import StickyPage from "@/components/sticky-page.vue";
export default {
  components: {
    StickyPage,
  },
  setup() {
    const yoHomeToBelair = "fuzzydice";
    return { yoHomeToBelair };
  },
};
</script>